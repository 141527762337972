import {rawHttp} from '../../services/http';
import {
    getOccupationalMedicineDataEndpoint,
    getOccupationalMedicineDocumentPreviewEndpoint,
    getOccupationalMedicineStateEndpoint,
} from './appointmentsEndpoints';
import {
    OccupationalMedicineData,
    OMPreviewDocumentType,
} from '../../types/appointments';

class AppointmentsApi {
    getOccupationalMedicineState(appointmentId: string) {
        return rawHttp.get<{filledByPatient: boolean}>(
            getOccupationalMedicineStateEndpoint(appointmentId),
        );
    }

    getOccupationalMedicineData(appointmentId: string) {
        return rawHttp.get<OccupationalMedicineData>(
            getOccupationalMedicineDataEndpoint(appointmentId),
        );
    }

    getOccupationalMedicineDocumentPreview(
        id: number,
        documentType: OMPreviewDocumentType,
    ) {
        return rawHttp.get(getOccupationalMedicineDocumentPreviewEndpoint(id), {
            params: {documentType},
            responseType: 'arraybuffer',
        });
    }
}

export const appointmentsApi = new AppointmentsApi();
