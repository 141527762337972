import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {MAGENTA, WHITE} from '../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '16px',
            backgroundColor: WHITE,
            [theme.breakpoints.up('md')]: {
                padding: '16px 47px',
                paddingBottom: 36,
            },
        },
        fillLink: {
            color: theme.palette.secondary.main,
        },
        listWrapper: {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            marginTop: 16,
            '& li:not(:first-child)': {
                marginTop: 32,
            },
        },
        dropzone: {
            marginTop: 8,
        },
        link: {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: MAGENTA,
        },
    }),
);

export default useStyles;
