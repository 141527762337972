import React, {memo, useCallback, useEffect, useState} from 'react';
import {Box, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {
    Button,
    Loading,
    MedicAppointmentDetailsHeaderDesktop,
    MedicAppointmentDetailsHeaderMobile,
} from '../../../components';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {
    fetchAppointmentDetails,
    fetchOccupationalMedicineData,
    selectAppointmentDetails,
    selectOccupationalMedicineData,
} from '../../../store/appointmentDetailsSlice';
import appointmentsProvider from '../../../services/appointmentsProvider';
import {ConfirmOrRejectModal} from '../../../components/index';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {Color} from '@material-ui/lab/Alert/Alert';
import {CustomAlert} from '../../../components/common/feedback';
import {
    AppointmentGroupEnum,
    AppointmentStateEnum,
} from '../../../types/appointments';
import {
    appointmentStatusBothSidesConnected,
    getAppointmentRoute,
} from '../../../utils/appointment';
import {useHistory} from 'react-router';
import {MAGENTA} from '../../../const/colors';
import clsx from 'clsx';
import {isNotNullOrUndefined} from '../../../utils/lang';

const actionButtonMobileCommon = {
    width: '100%',
    lineHeight: 1.35,
};

const useStyles = makeStyles(() => ({
    actionButtonMobile: {
        ...actionButtonMobileCommon,
    },
    actionButtonMobilePrimary: {
        ...actionButtonMobileCommon,
        color: MAGENTA,
        border: `1px solid ${MAGENTA}`,
        marginBottom: '16px',
    },
    actionButtonDesktop: {
        padding: '0 48px',
        lineHeight: 1.35,
    },
}));

const MedicAppointmentBasicDetails = memo(
    function MedicAppointmentBasicDetails() {
        const [alertInfo, setAlertInfo] =
            useState<{severity: Color; message: string}>();

        const [alertOpen, setAlertOpen] = useState<boolean>(false);

        const [settleAppointmentModalOpen, setSettleAppointmentLoading] =
            useState(false);

        const [cancelAppointmentModalOpen, setCancelAppointmentModalOpen] =
            useState(false);

        const [cancelAppointmentLoading, setCancelAppointmentLoading] =
            useState(false);

        const [
            endAppointmentAheadOfTimeLoading,
            setEndAppointmentAheadOfTimeLoading,
        ] = useState(false);

        const [
            endAppointmentAheadOfTimeModalOpen,
            setEndAppointmentAheadOfTimeModalOpen,
        ] = useState(false);

        const occupationalMedicineData = useAppSelector(
            selectOccupationalMedicineData,
        );

        const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
        const {t: tAppointment} = useTranslation(i18nNamespaces.APPOINTMENT);
        const {t: tAppointmentDetails} = useTranslation(
            i18nNamespaces.APPOINTMENT_DETAILS,
        );
        const classes = useStyles();
        const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

        const history = useHistory();
        const dispatch = useAppDispatch();

        const appointment = useAppSelector(selectAppointmentDetails);

        const loading =
            cancelAppointmentLoading ||
            endAppointmentAheadOfTimeLoading ||
            settleAppointmentModalOpen;

        const cantBeSettled =
            appointment?.appointmentGroup ===
                AppointmentGroupEnum.OccupationalMedicineAppointment &&
            (!Boolean(appointment.icd10Code) ||
                !occupationalMedicineData?.filledByMedic);

        const handleAppointmentCancellation = useCallback(() => {
            setCancelAppointmentModalOpen(true);
        }, []);

        const handleAppointmentCancellationConfirmed = useCallback(async () => {
            setCancelAppointmentLoading(true);
            setCancelAppointmentModalOpen(false);
            await appointmentsProvider.removeAppointment(appointment.id);
            await dispatch(
                fetchAppointmentDetails({
                    appointmentId: appointment.id,
                    fetchSilently: false,
                }),
            );
            setCancelAppointmentLoading(false);
        }, [appointment?.id]);

        const handleAppointmentCancellationRejected = useCallback(() => {
            setCancelAppointmentModalOpen(false);
        }, []);

        const handleEndAppointmentAheadOfTime = useCallback(() => {
            setEndAppointmentAheadOfTimeModalOpen(true);
        }, []);

        const handleEndAppointmentAheadOfTimeConfirmed =
            useCallback(async () => {
                setEndAppointmentAheadOfTimeLoading(true);
                setEndAppointmentAheadOfTimeModalOpen(false);
                await appointmentsProvider.completeAppointment(appointment.id);
                await dispatch(
                    fetchAppointmentDetails({
                        appointmentId: appointment.id,
                        fetchSilently: false,
                    }),
                );
                setEndAppointmentAheadOfTimeLoading(false);
            }, [appointment?.id]);

        const handleEndAppointmentAheadOfTimeRejected = useCallback(() => {
            setEndAppointmentAheadOfTimeModalOpen(false);
        }, []);

        const settleAppointmentClickHandler = useCallback(async () => {
            if (
                (appointment.icd10Code &&
                    appointment.medicalInterview &&
                    (appointment.drugMedicalRecommendations ||
                        appointment.referralMedicalRecommendations ||
                        appointment.otherMedicalRecommendations)) ||
                (appointment.appointmentGroup ===
                    AppointmentGroupEnum.OccupationalMedicineAppointment &&
                    appointment.icd10Code)
            ) {
                setSettleAppointmentLoading(true);
                await appointmentsProvider.settleAppointment(appointment.id);
                await dispatch(
                    fetchAppointmentDetails({
                        appointmentId: appointment.id,
                        fetchSilently: false,
                    }),
                );
                setSettleAppointmentLoading(false);
            } else {
                const message = [];
                if (!isNotNullOrUndefined(appointment.medicalInterview))
                    message.push(tAppointment('interviewWithPatient'));
                if (!appointment.icd10Code)
                    message.push(tAppointment('icd10Code'));
                if (
                    !isNotNullOrUndefined(
                        appointment.drugMedicalRecommendations,
                    ) &&
                    !isNotNullOrUndefined(
                        appointment.referralMedicalRecommendations,
                    ) &&
                    !isNotNullOrUndefined(
                        appointment.otherMedicalRecommendations,
                    )
                )
                    message.push(tAppointment('oneOfRecommendations'));

                setAlertInfo({
                    severity: 'error',
                    message:
                        tAppointment('settleAppointmentIcd10CodeNotSetError') +
                        message.join(', '),
                });
                setAlertOpen(true);
            }
        }, [
            appointment?.id,
            appointment?.icd10Code,
            appointment?.medicalInterview,
            appointment?.drugMedicalRecommendations,
            appointment?.referralMedicalRecommendations,
            appointment?.otherMedicalRecommendations,
        ]);

        const endAppointmentAheadOfTimeButtonDisplay =
            appointment && appointmentStatusBothSidesConnected(appointment);

        const settleButtonDisplay =
            appointment &&
            !appointment.state.isSettled &&
            appointment.state.state === AppointmentStateEnum.Completed;

        const joinAppointmentClickHandler = () => {
            history.push(getAppointmentRoute(appointment.type, appointment.id));
        };

        useEffect(() => {
            if (appointment)
                dispatch(
                    fetchOccupationalMedicineData({
                        appointmentId: appointment?.id,
                    }),
                );
        }, [dispatch, appointment]);

        const renderJoinAppointmentButton = (mobile: boolean) =>
            appointment?.state.state === AppointmentStateEnum.InProgress ? (
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={clsx({
                        [classes.actionButtonMobile]: mobile,
                        [classes.actionButtonDesktop]: !mobile,
                    })}
                    style={{
                        marginLeft: !mobile ? 10 : null,
                    }}
                    onClick={joinAppointmentClickHandler}
                >
                    {tAppointmentDetails('joinAppointment')}
                </Button>
            ) : null;

        const renderFinishAppointmentAheadOfTimeButton = (mobile: boolean) =>
            endAppointmentAheadOfTimeButtonDisplay ? (
                <Button
                    className={clsx({
                        [classes.actionButtonMobilePrimary]: mobile,
                        [classes.actionButtonDesktop]: !mobile,
                    })}
                    variant={'outlined'}
                    onClick={handleEndAppointmentAheadOfTime}
                >
                    {tCommon('finishAheadOfTime')}
                </Button>
            ) : null;

        const renderSettleAppointmentButton = (mobile: boolean) =>
            settleButtonDisplay ? (
                <Button
                    className={clsx({
                        [classes.actionButtonMobile]: mobile,
                        [classes.actionButtonDesktop]: !mobile,
                    })}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={settleAppointmentClickHandler}
                    disabled={cantBeSettled}
                >
                    {tAppointment('settle')}
                </Button>
            ) : null;

        const renderAppointmentBasicDetails = (mobile: boolean) => {
            const appointmentBasicDetailsProps = {
                appointment,
                joinAppointmentButton: renderJoinAppointmentButton(mobile),
                endAppointmentAheadOfTimeButton:
                    renderFinishAppointmentAheadOfTimeButton(mobile),
                settleAppointmentButton: renderSettleAppointmentButton(mobile),
                onCancelAppointment: handleAppointmentCancellation,
            };

            return mobile ? (
                <MedicAppointmentDetailsHeaderMobile
                    {...appointmentBasicDetailsProps}
                />
            ) : (
                <MedicAppointmentDetailsHeaderDesktop
                    {...appointmentBasicDetailsProps}
                />
            );
        };

        return (
            <Box>
                {appointment ? renderAppointmentBasicDetails(!smUp) : null}
                <ConfirmOrRejectModal
                    open={cancelAppointmentModalOpen}
                    primaryText={tAppointment(
                        'pleaseConfirmYouWantToCancelTheAppointment',
                    )}
                    confirmButtonText={tCommon('confirm')}
                    onConfirm={handleAppointmentCancellationConfirmed}
                    onReject={handleAppointmentCancellationRejected}
                />
                <ConfirmOrRejectModal
                    open={endAppointmentAheadOfTimeModalOpen}
                    primaryText={tAppointment(
                        'pleaseConfirmYouWantToCompleteAppointmentAheadOfTime',
                    )}
                    confirmButtonText={tCommon('confirm')}
                    onConfirm={handleEndAppointmentAheadOfTimeConfirmed}
                    onReject={handleEndAppointmentAheadOfTimeRejected}
                />
                <Loading withBackdrop={true} loading={loading} />
                {alertInfo && (
                    <CustomAlert
                        {...alertInfo}
                        open={alertOpen}
                        onClose={() => setAlertOpen(false)}
                    />
                )}
            </Box>
        );
    },
);

export default MedicAppointmentBasicDetails;
